<template>
  <div class="flex flex-1 flex-col gap-2 border-b border-grid px-5 py-2">
    <div class="flex flex-1 flex-col">
      <div class="mt-2 text-xs font-semibold text-gray-100">Instructions</div>
      <div
        class="mt-3 flex flex-1 flex-col gap-2 pr-2"
        v-for="(instruction, index) in instructions"
        :key="instruction"
        v-show="index + 1 == page"
      >
        <div class="text-xs font-medium text-gray-100">{{ page }}. <span v-html="instruction" /></div>
        <div class="mt-2 flex h-64 w-full items-center justify-center">
          <a
            target="_blank"
            :href="`https://the-tie-sigdev.s3.us-east-2.amazonaws.com/alert_instructions/slack_${page}.png`"
          >
            <img
              class="max-w-3/4 m-auto h-auto max-h-64 w-auto rounded"
              :src="`https://the-tie-sigdev.s3.us-east-2.amazonaws.com/alert_instructions/slack_${page}.png`"
            />
          </a>
        </div>
      </div>
    </div>
    <BasePaginate v-model="page" :pages="instructions.length" class="mx-auto mb-4 mt-10 w-98" />
  </div>
</template>

<script setup>
import { ref } from 'vue';

const page = ref(1);

const instructions = ref([
  `Choose the workspace and channel you would like to send alerts in. Right-click this channel and select
          <span class="rounded bg-gray-700 px-1">Open channel details</span>.`,
  `Select the <span class="rounded bg-gray-700 px-1">Integrations</span> tab. Select the
          <span class="rounded bg-gray-700 px-1">Add an App</span> option.`,
  `Search for <span class="rounded bg-gray-700 px-1">Incoming Webhooks</span>. If you have not used this app
          before, you will have to install it. Once installed, select the app.`,
  `Select
          <span class="rounded bg-gray-700 px-1">Configuration</span>, which will redirect you to a webpage.`,
  `Select  <span class="rounded bg-gray-700 px-1">Add to Slack</span>.`,
  `Select the channel that you want to connect the webhook to. Select the
          <span class="rounded bg-gray-700 px-1">Add Incoming WebHooks integration</span> button.`,
  `You should see your Webhook URL. Copy this link and paste it into the
          <span class="rounded bg-gray-700 px-1">Link</span> field below.`
]);
</script>
